import avatar from "../../../assets/images/user/avatar-1.jpg";

const emailData = [
  {
    id: 1,
    from: "Maggy Rime",
    email: "johndoe7869@gmail.com",
    thumbnail: avatar,
    welcometext: "Hello Dear...",
    subject: "Here You Have New Opportunity...",
    time: "08:23 AM",
    emailExcerpt:
      "How to Plan Execution stages and Assign Colabrators",
    emailContent:
      "<p>Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit ac nulla. Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus.\n\nCurabitur at ipsum ac tellus semper interdum. Mauris ullamcorper purus sit amet nulla. Quisque arcu libero, rutrum ac, lobortis vel, dapibus at, diam. <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>",
    unread: true,
    attachment: false,
    starred: true,
    important: false,
    inbox: true,
    sent: false,
    draft: false,
    spam: false,
    trash: false
  },
  {
    id: 2,
    from: "Nicolas Harget",
    email: "johndoe7869@gmail.com",
    thumbnail: avatar,
    welcometext: "Hello Dear...",
    subject: "Maecenas rhoncus aliquam lacus.",
    time: "6:39 AM",
    emailExcerpt: "How to Provide real time update to the client ",
    emailContent:
      "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>Quisque id justo sit amet sapien dignissim vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla dapibus dolor vel est. Donec odio justo, sollicitudin ut, suscipit a, feugiat et, eros.\n\nVestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue. Aliquam erat volutpat.\n\nIn congue. Etiam justo. Etiam pretium iaculis justo.",
    unread: true,
    attachment: false,
    starred: true,
    important: true,
    inbox: true,
    sent: false,
    draft: true,
    spam: false,
    trash: false
  },
 
];

export default emailData;
